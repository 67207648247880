import './Home.css';

function Home() {
    return (
        <div className="home-component">
            <h1>Seja bem-vindo ao batalha de monstros!</h1>
        </div>
    );
}

export default Home;
