import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { getMonsters } from '../../services/monsterService';
import { Monster } from '../../types/Monster';
import MonsterCards from '../monster-cards/MonsterCards';

import CardMonster from '../card/CardMonster';
import './BattleField.css';

function BattleField() {
    const [monsters, setMonsters] = useState<Monster[] | undefined>();
    function handleMonsters() {
        const monstersData = getMonsters();

        if (monstersData !== null) {
            const newMonsters = monstersData;

            setMonsters(newMonsters);
        }
    }

    useEffect(() => {
        handleMonsters();
    }, []);

    function handleChange(monsterId: string, monsterNum: '1' | '2') {
        const selectedMonster = monsters!.filter(
            (monster) => monster.id === monsterId,
        )[0];

        switch (monsterNum) {
            case '1':
                setMonsterOne(selectedMonster);
                break;
            case '2':
                setMonsterTwo(selectedMonster);
                break;
        }
    }

    const [monsterOne, setMonsterOne] = useState<Monster | undefined>();

    const [monsterTwo, setMonsterTwo] = useState<Monster | undefined>();

    const [winner, setWinner] = useState<Monster | undefined>();

    const isDisabledButton = () => {
        if (!monsterOne?.id || !monsterTwo?.id) {
            return true;
        }

        if (winner) {
            return true;
        }

        return false;
    };

    function calculateBattle(firstMonster: Monster, secondMonster: Monster) {
        let firstMonsterHP = firstMonster.hp;
        let secondMonsterHP = secondMonster.hp;

        const getDamage = (attacker: Monster, defender: Monster) => {
            const damage = attacker.attack - defender.defense;
            return damage > 0 ? damage : 1;
        };

        const setFirstAttacker = (m1: Monster, m2: Monster) => {
            if (m1.speed > m2.speed) {
                return m1;
            }

            if (m1.speed < m2.speed) {
                return m2;
            }

            return m1.attack > m2.attack ? m1 : m2;
        };

        const firstAttacker = setFirstAttacker(firstMonster, secondMonster);

        while (firstMonsterHP > 0 && secondMonsterHP > 0) {
            if (firstAttacker.id === firstMonster.id) {
                secondMonsterHP -= getDamage(firstMonster, secondMonster);
                if (secondMonsterHP <= 0) {
                    break;
                }
                firstMonsterHP -= getDamage(secondMonster, firstMonster);
            } else {
                firstMonsterHP -= getDamage(secondMonster, firstMonster);
                if (firstMonsterHP <= 0) {
                    break;
                }
                secondMonsterHP -= getDamage(firstMonster, secondMonster);
            }
        }

        const winner = firstMonsterHP > 0 ? firstMonster : secondMonster;
        return winner;
    }

    function handleBattle() {
        if (!monsterOne || !monsterTwo) return;

        const battleWinner = calculateBattle(monsterOne, monsterTwo);
        setWinner(battleWinner);
    }

    function renderWinner() {
        if (winner) {
            return (
                <div className="winner-info">
                    <p className="winner-title">Campeão</p>
                    <CardMonster monsterValue={winner}></CardMonster>
                </div>
            );
        }
    }

    function handleResetBattle() {
        setMonsterOne(undefined);
        setMonsterTwo(undefined);
        setWinner(undefined);
        handleMonsters();
    }

    return (
        <div className="battle-field">
            <h2> Campo de batalha </h2>
            <div className="battle-ground">
                <div className="monster-container">
                    <div>
                        <InputLabel id="monster-1">
                            Selecionar Monstro 1
                        </InputLabel>
                        <Select
                            labelId="monster-1"
                            disabled={Boolean(winner?.id)}
                            value={monsterOne?.id || ''}
                            onChange={(e) => handleChange(e.target.value, '1')}
                        >
                            {monsters &&
                                monsters.map((monster) => (
                                    <MenuItem
                                        key={monster.id}
                                        value={monster.id || ''}
                                    >
                                        {monster.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>

                    <div>
                        <InputLabel id="monster-2">
                            Selecionar Monstro 2
                        </InputLabel>
                        <Select
                            labelId="monster-2"
                            disabled={Boolean(winner?.id)}
                            value={monsterTwo?.id || ''}
                            onChange={(e) => handleChange(e.target.value, '2')}
                        >
                            {monsters &&
                                monsters.map((monster) => (
                                    <MenuItem
                                        key={monster.id}
                                        value={monster.id || ''}
                                    >
                                        {monster.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>

                    <div>
                        <Button
                            variant="text"
                            disabled={isDisabledButton()}
                            onClick={handleBattle}
                        >
                            Batalhar
                        </Button>

                        <Button
                            variant="text"
                            disabled={Boolean(!winner?.id)}
                            onClick={handleResetBattle}
                        >
                            Reiniciar
                        </Button>
                    </div>
                </div>
                <div className="img">
                    <div className="monster">
                        {monsterOne && !winner ? (
                            <CardMonster
                                monsterValue={monsterOne}
                            ></CardMonster>
                        ) : (
                            <p></p>
                        )}
                    </div>

                    <div className="battle">{renderWinner()}</div>

                    <div className="monster">
                        {monsterTwo && !winner ? (
                            <CardMonster
                                monsterValue={monsterTwo}
                            ></CardMonster>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </div>
            <h4>Lista de monstros:</h4>
            {monsters ? (
                <MonsterCards monstersValue={monsters}></MonsterCards>
            ) : (
                <p>Nenhum monstro cadastrado!</p>
            )}
        </div>
    );
}

export default BattleField;
