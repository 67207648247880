import { Card, CardContent, CardMedia } from '@mui/material';
import { useState } from 'react';
import { Monster } from '../../types/Monster';

function CardMonster({ monsterValue }: { monsterValue: Monster }) {
    const [monster] = useState<Monster>(monsterValue);

    return (
        <Card
            sx={{ minWidth: 150, maxWidth: 150, height: 230 }}
            key={monster.name + monster.attack}
            className="card-monster"
        >
            <CardMedia component="img" height="80" src={monster.imageUrl} />
            <CardContent
                className="card-content"
                style={{
                    padding: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 6,
                    margin: 0,
                }}
            >
                <p style={{ padding: 2, margin: 0, fontSize: 15 }}>
                    <b>{monster.name}</b>
                </p>
                <p style={{ padding: 2, margin: 0, fontSize: 12 }}>
                    <b>Vida:</b> {monster.hp}
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                    <b>Ataque:</b> {monster.attack}
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                    <b>Defesa:</b> {monster.defense}
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 12 }}>
                    <b>Velocidade:</b> {monster.hp}
                </p>
            </CardContent>
        </Card>
    );
}

export default CardMonster;
