import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { saveMonster } from '../../services/monsterService';
import { MonsterFormValues } from '../../types/MonsterFormValues';
import './CreateMonster.css';

function CreateMonster() {
    const initialFormState: MonsterFormValues = {
        name: '',
        attack: 0,
        defense: 0,
        speed: 0,
        hp: 0,
        imageUrl: '',
    };
    const [formValues, setFormValues] = useState(initialFormState);

    const [isSubmitted, setIsSubmitted] = useState(false);

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;

        setFormValues((prevValues) => {
            const newValue = {
                [name]:
                    name === 'name' || name === 'imageUrl'
                        ? value
                        : Number(value),
            };
            return {
                ...prevValues,
                ...newValue,
            };
        });
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        setIsSubmitted(true);

        if (validateForm()) {
            try {
                saveMonster(formValues);
                alert('Monstro cadastrado!');
            } catch (error) {
                console.error(`Error on save form`, error);
                alert('Erro ao salvar formulário!');
                return;
            }
            setFormValues(initialFormState);
            setIsSubmitted(false);
        }
    }

    function validateForm() {
        return Object.values(formValues).every(
            (value) => value !== '' && value !== 0,
        );
    }

    return (
        <div className="create-monster">
            <h1>Cadastro de Monstro</h1>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    label="Nome"
                    name="name"
                    variant="outlined"
                    required={true}
                    type="text"
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.name}
                    helperText={
                        isSubmitted && !formValues.name
                            ? 'Nome é obrigatório'
                            : ''
                    }
                />

                <TextField
                    label="Ataque"
                    name="attack"
                    variant="outlined"
                    required={true}
                    type="number"
                    value={formValues.attack || ''}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.attack}
                    helperText={
                        isSubmitted && !formValues.attack
                            ? 'Ataque é obrigatório'
                            : ''
                    }
                />

                <TextField
                    label="Defesa"
                    name="defense"
                    variant="outlined"
                    required={true}
                    type="number"
                    value={formValues.defense || ''}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.defense}
                    helperText={
                        isSubmitted && !formValues.defense
                            ? 'Defesa é obrigatória'
                            : ''
                    }
                />

                <TextField
                    label="Velocidade"
                    name="speed"
                    variant="outlined"
                    required={true}
                    type="number"
                    value={formValues.speed || ''}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.speed}
                    helperText={
                        isSubmitted && !formValues.speed
                            ? 'Velocidade é obrigatória'
                            : ''
                    }
                />

                <TextField
                    label="HP"
                    name="hp"
                    variant="outlined"
                    required={true}
                    type="number"
                    value={formValues.hp || ''}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.hp}
                    helperText={
                        isSubmitted && !formValues.hp ? 'HP é obrigatório' : ''
                    }
                />

                <TextField
                    label="Imagem URL"
                    name="imageUrl"
                    variant="outlined"
                    required={true}
                    type="text"
                    value={formValues.imageUrl}
                    onChange={handleInputChange}
                    error={isSubmitted && !formValues.imageUrl}
                    helperText={
                        isSubmitted && !formValues.imageUrl
                            ? 'URL da imagem é obrigatória'
                            : ''
                    }
                />

                <Button type="submit">Salvar</Button>
            </form>
        </div>
    );
}

export default CreateMonster;
