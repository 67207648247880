import { v4 as uuidv4 } from 'uuid';
import { Monster } from '../types/Monster';
import { MonsterFormValues } from '../types/MonsterFormValues';

const LOCALSTORAGE_MONSTERS_KEY = 'monsters';

export const saveMonster = (data: MonsterFormValues) => {
    const monsters = getMonsters();
    const id = generateRandomUidForMonster(monsters);

    if (monsters === null) {
        localStorage.setItem(
            LOCALSTORAGE_MONSTERS_KEY,
            JSON.stringify([{ ...data, id }]),
        );
        return;
    }

    const updatedMonsters = [...monsters, { ...data, id }];

    localStorage.setItem(
        LOCALSTORAGE_MONSTERS_KEY,
        JSON.stringify(updatedMonsters),
    );
};

export const getMonsters = (): Monster[] | null => {
    const data = localStorage.getItem(LOCALSTORAGE_MONSTERS_KEY);

    if (data) {
        const parsedData = JSON.parse(data);
        return parsedData;
    }

    return null;
};

/**
 *
 * It is needed because the application doesn't have a backend with database to generate unique ids.
 *
 */
export function generateRandomUidForMonster(
    monsters: Monster[] | null,
): string {
    if (monsters === null) {
        return uuidv4();
    }

    let newId: string = uuidv4();

    // eslint-disable-next-line
    while (monsters.some((monster) => monster.id === newId)) {
        newId = uuidv4();
    }

    return newId;
}
