import { useState } from 'react';
import { Monster } from '../../types/Monster';
import CardMonster from '../card/CardMonster';
import './MonsterCards.css';

function MonsterCards({ monstersValue }: { monstersValue: Monster[] }) {
    const [monsters] = useState<Monster[]>(monstersValue);

    return (
        <div className="monster-cards">
            {monsters &&
                monsters.map((monster) => (
                    <CardMonster monsterValue={monster}></CardMonster>
                ))}
        </div>
    );
}

export default MonsterCards;
