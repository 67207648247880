import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    const headerTitle = 'Batalha de Monstro';

    return (
        <header className="header">
            <h2>
                <Link to="/" className="link">
                    {headerTitle}
                </Link>
            </h2>

            <div className="options">
                <Button variant="text">
                    <Link to="/create-monster" className="link">
                        Criar monstro
                    </Link>
                </Button>
                <Button variant="text">
                    <Link to="/battle-field" className="link">
                        Campo de batalha
                    </Link>
                </Button>
            </div>
        </header>
    );
}

export default Header;
