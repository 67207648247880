import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import BattleField from './components/battle-field/BattleField';
import CreateMonster from './components/create-monster/CreateMonster';
import Header from './components/header/Header';
import Home from './components/home/Home';

function App() {
    return (
        <Router>
            <div className="App">
                <Header></Header>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/create-monster" element={<CreateMonster />} />
                    <Route path="/battle-field" element={<BattleField />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
